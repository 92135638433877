import * as React from 'react';
import { Button, Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { loginAction } from '../../redux/actions/userActions';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'src/firebase/firebase';
import { appMakeStyles } from 'src/theme/theme';
import LoadingCircle from '../widgets/LoadingCircle';
import LoadingButton from '../widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useIntl } from 'react-intl';

const useStyles = appMakeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  titleBox: {
    marginTop: 40,
    marginBottom: 20
  },
  textField: {
    width: 400
  },
  textFieldContainer: {
    marginBottom: 15
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { $t } = useIntl();
  const ui = useAppSelector(({ ui }) => ui);

  const [user, authLoading] = useAuthState(auth);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginAction({ email, password }));
  };

  return authLoading ? (
    <LoadingCircle />
  ) : user ? (
    <Redirect to="/" />
  ) : (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.titleBox}>
          <Typography variant="h4" component="div">
            {$t({ id: 'login' })}
          </Typography>
        </Box>
        <Box className={classes.textFieldContainer}>
          <TextField
            className={classes.textField}
            onChange={(e) => setEmail(e.target.value)}
            id="login-email"
            label="Email"
            variant="outlined"
            type="email"
            required
          />
        </Box>
        <Box className={classes.textFieldContainer}>
          <TextField
            className={classes.textField}
            onChange={(e) => setPassword(e.target.value)}
            id="login-password"
            label={$t({ id: 'password' })}
            variant="outlined"
            type="password"
            required
            autoComplete="current-password"
          />
        </Box>
        <Box className={classes.buttonBox}>
          <form onSubmit={handleSubmit}>
            <LoadingButton
              isLoading={ui.loadingButton.isLoginLoading}
              content={$t({ id: 'submit' })}
              type="submit"
            />
          </form>
          <Button variant="outlined" onClick={() => history.push('/forgotpassword')}>
            {$t({ id: 'forgotPassword' })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
