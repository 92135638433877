import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { getCount } from '../../../util/database';
import { CountData } from './MultiCompanyChart';
import { useRemoteData } from '../../../hooks/chartHooks';
import LoadingCircle from 'src/components/widgets/LoadingCircle';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import ChartFilter from 'src/components/utils/ChartFilter';
import BadRequest from '../../widgets/BadRequest';
import CustomDatePicker from 'src/components/utils/CustomDatePicker';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';

const SingleCompanyChart: React.FC = () => {
  // User Data and Claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  const [companies, setCompanies] = useState<string[]>([]);

  // Select Button
  const [companySelected, setCompanySelected] = useStoredState<string>(
    'singleCompanyChartSelect',
    ''
  );

  const handleSelectChange = (event) => {
    setCompanySelected(event.target.value);
  };

  // Filter Related
  const [filteredValues, setFilteredValues] = useState<string[]>([]);

  const handleFilterChange = (values: string[]) => {
    const getValues = new Set<string>();
    values.forEach((value: string) => {
      getValues.add(value);
    });
    setFilteredValues(Array.from(getValues));
  };

  // Date Picker Related
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => {
    if (value) setDateRange(value);
  };

  // Fetching Data
  const fetchCount = useCallback(async () => {
    if (companySelected) {
      if (dateRange) {
        const count = await getCount([companySelected], dateRange).then((count) => {
          return [{ ...count }];
        });
        return count;
      } else {
        const count = await getCount([companySelected]).then((count) => {
          return [{ ...count }];
        });
        return count;
      }
    }
    return [];
  }, [companySelected, dateRange]);

  // Chart Config
  const xLabels = [
    filteredValues.includes('sessionCREATE') ? null : 'Session Create',
    filteredValues.includes('sessionDELETE') ? null : 'Session Delete',
    filteredValues.includes('sessionUPDATE') ? null : 'Session Update',
    filteredValues.includes('taskCREATE') ? null : 'Task Create',
    filteredValues.includes('taskDELETE') ? null : 'Task Delete',
    filteredValues.includes('taskUPDATE') ? null : 'Task Update'
  ].filter(Boolean);

  const handleData = useCallback(
    (data: Record<string, Record<string, Record<string, number>>>[]) => {
      const countData: CountData = {
        sessionCREATE: 0,
        sessionDELETE: 0,
        sessionUPDATE: 0,
        taskCREATE: 0,
        taskDELETE: 0,
        taskUPDATE: 0
      };

      data.map((_data) => {
        Object.keys(_data[companySelected]).forEach((domain: string) => {
          const domainData = _data[companySelected][domain];

          Object.keys(countData).forEach((k) => {
            countData[k] += domainData[k];
          });
        });
      });

      const transformedData = Object.keys(countData).map((k) =>
        filteredValues.includes(k) ? null : countData[k]
      );

      const filteredData = transformedData.filter((element) => {
        return element !== null && element !== undefined;
      });

      return filteredData;
    },
    [filteredValues, companySelected]
  );

  useEffect(() => {
    claims
      ? claims.keyUser
        ? setCompanies(claims.company as unknown as string[])
        : setCompanies([claims.company])
      : null;
  }, [claims]);

  const [data, loading, error] = useRemoteData(fetchCount, handleData);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {/* Company Select Button */}
        <FormControl sx={{ width: 200, marginTop: 1 }} fullWidth>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={companySelected}
            label="Company"
            onChange={handleSelectChange}
          >
            <MenuItem value={''}>
              <em>Nenhum</em>
            </MenuItem>
            {companies.map((company) => {
              return (
                <MenuItem value={company} key={company}>
                  {company}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* Datepicker */}
        <CustomDatePicker onChange={handleDateChange} />
        {/* Filter */}
        {companySelected && data ? <ChartFilter onChange={handleFilterChange} /> : null}
      </Box>

      {/* Chart */}
      {!companySelected ? null : loading ? (
        <Box sx={{ marginTop: 10 }}>
          <LoadingCircle />
        </Box>
      ) : error ? (
        <Box sx={{ marginTop: 5 }}>
          <BadRequest text={error.message} />
        </Box>
      ) : data?.length ? (
        <BarChart
          sx={{ marginBottom: 3 }}
          width={800}
          height={450}
          series={[
            {
              data: data,
              label: 'Number of',
              id: 'DataId'
            }
          ]}
          xAxis={[{ data: xLabels, scaleType: 'band' }]}
        />
      ) : null}
    </Box>
  );
};

export default SingleCompanyChart;
