"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodeTypeFromStringValue = exports.ManualActionNodeTypes = exports.ParentSpecificData = exports.EdgeTypes = exports.NodeTypeValues = exports.NodeTypeKeys = exports.NodeType = exports.FlowEdge = exports.FlowNode = exports.HandleType = void 0;
const Vertex_1 = require("./Vertex");
var HandleType;
(function (HandleType) {
    HandleType["START"] = "START";
    HandleType["SINGLE"] = "SINGLE";
    HandleType["MULTIPLE"] = "MULTIPLE";
    HandleType["END"] = "END";
})(HandleType = exports.HandleType || (exports.HandleType = {}));
class FlowNode {
    constructor() {
        this.type = NodeType.Start;
        this.position = new Vertex_1.Vertex();
    }
}
exports.FlowNode = FlowNode;
class FlowEdge {
    constructor() {
        this.source = '';
        this.sourceHandle = '';
        this.target = '';
        this.targetHandle = '';
    }
}
exports.FlowEdge = FlowEdge;
var NodeType;
(function (NodeType) {
    NodeType["Start"] = "Start";
    NodeType["LabelDesign"] = "LabelDesign";
    NodeType["ManualInputField"] = "ManualInputField";
    NodeType["TakePicture"] = "TakePicture";
    NodeType["AutoFillData"] = "AutoFillData";
    NodeType["Conditional"] = "Conditional";
    NodeType["LogicalBlock"] = "LogicalBlock";
    NodeType["CustomMarker"] = "CustomMarker";
    NodeType["End"] = "End";
})(NodeType = exports.NodeType || (exports.NodeType = {}));
exports.NodeTypeKeys = Object.keys(NodeType);
exports.NodeTypeValues = Object.values(NodeType);
var EdgeTypes;
(function (EdgeTypes) {
    EdgeTypes["Custom"] = "Custom";
})(EdgeTypes = exports.EdgeTypes || (exports.EdgeTypes = {}));
/** Classes which parent a node should extendo from this */
class ParentSpecificData {
    constructor() {
        this.name = '';
        this.contentChildrenIds = [];
    }
}
exports.ParentSpecificData = ParentSpecificData;
exports.ManualActionNodeTypes = [
    NodeType.ManualInputField,
    NodeType.TakePicture,
    NodeType.CustomMarker
];
function getNodeTypeFromStringValue(value) {
    return value && exports.NodeTypeValues.includes(value) ? value : undefined;
}
exports.getNodeTypeFromStringValue = getNodeTypeFromStringValue;
