import { GridSortDirection } from '@mui/x-data-grid';
import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter
} from 'firebase/firestore';
import { useEffect } from 'react';
import useAvgAndSum from './useAvgAndSum';
import useCountDataTotal from 'flyid-ui-components/dist/hooks/useCountDataTotal';
import useStateReducer from 'flyid-ui-components/dist/hooks/useStateReducer';

type StateType<T = any> = {
  lastDocument: QueryDocumentSnapshot<T> | null;
  isLoading: boolean;
  count: number;
  data: QueryDocumentSnapshot<T>[] | null;
};
const initialStateData: StateType = {
  lastDocument: null,
  isLoading: false,
  count: 0,
  data: null
};
/**
 * This hook will manage data pagination for the given query.
 *
 * If the dataQuery, column or columnOrder changes, onPageChange will be triggered, setting the
 * selected page to 0. This happens to avoid inconsistent states when the fetched data amount is not
 * enough to fill the data shown by the selected page.
 *
 * The data query must not use different columns for filtering and sorting.
 */
export const usePaginatedData = <T>(
  dataQuery: Query<T> | null,
  page: number,
  pageSize: number,
  column: string | null,
  columnOrder: GridSortDirection,
  onPageChange: (page: number) => void
) => {
  const totalsAndMeans = useAvgAndSum(dataQuery);
  const totalCount = useCountDataTotal(dataQuery);

  const [state, setState] = useStateReducer<StateType<T>>({ ...initialStateData });

  const checkData = (reset: boolean) => {
    const fetchedDataCount = reset ? 0 : state.data?.length ?? 0;

    if (totalCount !== null) {
      if (!!dataQuery && fetchedDataCount < pageSize * page + 1) {
        let currentDataQuery = query(dataQuery, limit(pageSize));
        // In case the user order by column (only startTime and endTime for now)
        if (columnOrder && column) {
          currentDataQuery = query(currentDataQuery, orderBy(column, columnOrder));
        }
        // If the query has already been made, start from the last element seen
        if (!reset && state.lastDocument) {
          currentDataQuery = query(currentDataQuery, startAfter(state.lastDocument));
        }

        setState({ isLoading: true });

        getCountFromServer(dataQuery)
          .then(async (countSnap) => {
            const _data = await getDocs(currentDataQuery);

            setState({
              data: [...(reset ? [] : state.data ?? []), ..._data.docs],
              isLoading: false,
              count: countSnap.data().count,
              lastDocument: _data.docs[_data.docs.length - 1]
            });

            if (reset) onPageChange(0);
          })
          .catch((err) => {
            console.error('Failed counting docs: ', err);
            setState({ ...initialStateData });
          });
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    checkData(false);
  }, [page, pageSize]);

  useEffect(() => {
    checkData(true);
  }, [columnOrder, column, dataQuery, totalCount]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    isLoading: state.isLoading,
    data: state.data,
    count: state.count,
    totalsAndMeans: totalsAndMeans
  };
};
