import BarChartIcon from '@mui/icons-material/BarChart';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FolderIcon from '@mui/icons-material/Folder';
import InventoryIcon from '@mui/icons-material/Inventory';
import NavBar, { NavGroups } from 'flyid-ui-components/dist/layout/NavBar';
import { useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { auth } from 'src/firebase/firebase';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectProfileClaims } from 'src/redux/selectors/userSelectors';

import noImg from '../../assets/avatar-no-img.png';
import logo from '../../assets/logo_fly_id.png';
import squareLogo from '../../assets/pid_logo_primary_m.png';
import { Actions } from '../../redux/actions/actionsTypes';
import { setLanguage as setLanguageAction } from '../../redux/reducers/localeReducer';
import { readableLocaleByLocale, SUPPORTED_LOCALES } from '../../util/locale';

const NavBarImpl: React.FC = (props) => {
  //Todo review usage
  const [authUser, authLoading] = useAuthState(auth);
  const signedIn = !authLoading && Boolean(authUser);
  const dispatch = useAppDispatch();
  const { $t } = useIntl();

  const { children } = props;

  const { userData, locale, claims } = useAppSelector((s) => ({
    userData: s.user,
    claims: selectProfileClaims(s),
    locale: s.locale
  }));

  const { profile, isLoaded: isProfileLoaded } = userData;
  const hasFlightLogsAnalytics = claims?.extraFeatures.flightLogsAnalytics;
  const hasUsageAnalytics = claims?.extraFeatures.usageAnalytics;

  const routes = useMemo(() => {
    const _routes: NavGroups = [];

    if (signedIn && profile && isProfileLoaded) {
      if (hasFlightLogsAnalytics) {
        _routes.push({
          key: 'flightlogs',
          name: $t({ id: 'flightLogs' }),
          icon: <FolderIcon />,
          children: [
            {
              name: $t({ id: 'flightLogsCharts' }),
              path: '/flightlogcharts',
              icon: <BarChartIcon />
            },
            {
              name: $t({ id: 'batteryHistory' }),
              path: '/batteryhistory',
              icon: <ContentPasteSearchIcon />
            }
          ]
        });
        if (hasUsageAnalytics) {
          _routes.push({
            key: 'sessions',
            name: $t({ id: 'sessionUsage' }),
            icon: <InventoryIcon />,
            children: [
              {
                name: $t({ id: 'sessionHistory' }),
                path: '/sessionhistory',
                icon: <ContentPasteSearchIcon />
              },
              {
                name: $t({ id: 'usageCounter' }),
                path: '/usagecounter',
                icon: <BarChartIcon />
              }
            ]
          });
        }
      }
    }
    return _routes;
  }, [signedIn, profile, isProfileLoaded, hasFlightLogsAnalytics, hasUsageAnalytics, locale]);

  //useCallback
  const showLogoutDialogConfirmation = () => {
    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'nav.logoutTitle' }),
          message: $t({ id: 'nav.logoutMsg' }),
          show: true
        }).setConfirmAction(Actions.LOGOUT)
      })
    );
  };

  return (
    <NavBar
      userData={userData}
      signedIn={signedIn}
      routes={routes}
      logo={logo}
      noImg={noImg}
      squareLogo={squareLogo}
      logoutAction={showLogoutDialogConfirmation}
      localesData={{
        onLocaleChange: (_locale) => dispatch(setLanguageAction(_locale)),
        supportedLocales: SUPPORTED_LOCALES,
        readableLocales: readableLocaleByLocale
      }}
    >
      {children}
    </NavBar>
  );
};

export default NavBarImpl;
