import * as React from 'react';
import { Button, Box, TextField, Typography } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateUi } from '../../redux/reducers/uiReducer';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { sendPasswordResetEmailAction } from '../../redux/actions/userActions';
import LoadingButton from '../widgets/LoadingButton';
import { useIntl } from 'react-intl';

const useStyles = appMakeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  titlesBox: {
    marginTop: 40,
    marginBottom: 20
  },
  textFieldContainer: {
    marginBottom: 15
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const { ui, locale } = useAppSelector(({ ui, locale }) => ({ ui, locale }));

  const [email, setEmail] = useState<string>('');

  const forgotPasswordHandler = () => {
    if (!email) {
      dispatch(
        updateUi({
          snackbar: {
            message: { msgCode: '420', msg: 'Invalid email!' },
            severity: 'error',
            show: true
          }
        })
      );

      return;
    }

    dispatch(sendPasswordResetEmailAction({ email, locale: locale.locale }));
  };

  return (
    <Box className={classes.container}>
      <Box onSubmit={forgotPasswordHandler} component="form">
        <Box className={classes.titlesBox}>
          <Typography component="div" variant="h4">
            {$t({ id: 'forgotPw.title' })}
          </Typography>
          <Typography component="div">{$t({ id: 'forgotPw.subtitle' })}</Typography>
        </Box>
        <Box className={classes.textFieldContainer}>
          <TextField
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            id="forgotpassword-email"
            label={$t({ id: 'forgotPw.input' })}
            variant="outlined"
            type="email"
            required
          />
        </Box>
        <Box className={classes.buttonBox}>
          <Button onClick={() => history.push('/login')} variant="outlined">
            {$t({ id: 'return' })}
          </Button>

          <LoadingButton
            isLoading={ui.loadingButton.isLoginLoading}
            content={$t({ id: 'submit' })}
            type="submit"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
