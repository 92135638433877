import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { CircularProgress } from '@mui/material';
import { isModeratorProf } from '../../util/helpers/user';

type ProtectedRouteProps = RouteProps & {
  modOnly?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...rest }) => {
  const { location, modOnly } = rest;

  if (!component) throw new Error("'component' must be non null!");

  const [authUser, authLoading] = useAuthState(auth);
  const { userData } = useAppSelector(({ user }) => ({ userData: user }));
  const { profile, emailVerified, isLoaded: isProfileLoaded } = userData;

  const signedIn = Boolean(!authLoading && authUser);
  const isLoginPreparing = authLoading;

  const pathname = location?.pathname;
  const Component = component;

  const render = (props) => {
    // Loading authentication or profile data
    if (isLoginPreparing) return <CircularProgress />;
    else if (signedIn) {
      if (modOnly && !isModeratorProf(profile)) {
        return <Redirect to="/" />;
      }

      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={render} />;
};

export default ProtectedRoute;
