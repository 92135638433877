import { Container, Typography } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import React from 'react';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { FormattedMessage } from 'react-intl';

const useStyles = appMakeStyles((theme) => ({
  container: { marginTop: 3 },
  margin: {
    marginBottom: theme.spacing(2)
  }
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const profile = useAppSelector((state) => state.user.profile);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.margin}>
        {profile.firstName} {profile.lastName}
      </Typography>
      <Typography variant="subtitle1" className={classes.margin}>
        <FormattedMessage id="main.msg" />
      </Typography>
    </Container>
  );
};

export default Home;
