import { immerable } from "immer";

export type MaybeImmerable<T = unknown> = T & {
  [immerable]?: boolean;
};

export class LoadingButtonState {
  [immerable]? = true;

  isLoginLoading = false;
  isSetPasswordLoading = false;
  isForgetPasswordLoading = false;
  isChangePasswordLoading = false;
  isAddUserLoading = false;
  isEditUserLoading = false;
  isAddDomainLoading = false;
  isEditDomainSettingsFieldsLoading = false;
  isBarcodePatternLoading = false;
  isCreateTaskLoading = false;
  isCreateApiKeyLoading = false;
  isAddUpdTableLoading = false;
  isManualInputFieldLoading = false;
  isEditApiKeyAuthDomainsLoading = false;
}
