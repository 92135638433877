import React, { useRef, useEffect, useState } from "react";
import { LogHeaders, LogData } from "src/util/logParser";

import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
} from "chart.js";

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

let LineChart;

type Props = {
  headers: LogHeaders | undefined;
  data: LogData | number | undefined;
  startTime: number | undefined;
  endTime: number | undefined;
};

const BatteryVoltage: React.FC<Props> = (props) => {
  const { headers, data, startTime, endTime } = props;

  const [batteryData, setBatteryData] = useState<string>("");
  const [dateData, setDateData] = useState<any>();

  useEffect(() => {
    if (data) {
      const batteryIndex = "04";
      const BatteryVoltage = 3;

      const batteryDataList = data[batteryIndex];
      const batteryVoltageDataList = batteryDataList.map((data) => ({
        x: new Intl.DateTimeFormat("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit"
        }).format(data[0]),
        y: data[BatteryVoltage]
      }));

      setBatteryData(batteryVoltageDataList);

      const dateIndex = batteryDataList.map((data) => ({
        date: new Intl.DateTimeFormat("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        }).format(data[0])
      }));

      setDateData(dateIndex);
    }
  }, [data]);

  const canvasRef = React.createRef<HTMLCanvasElement>();

  const renderChart = () => {
    const chartRef = canvasRef.current!.getContext("2d");

    if (typeof LineChart !== "undefined") LineChart.destroy();

    LineChart = new Chart(chartRef!, {
      type: "line",
      data: {
        datasets: [
          {
            data: batteryData,
            borderColor: "rgb(75, 192, 192)"
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Battery Voltage - " + dateData[0].date
          }
        }
      }
    });
  };

  useEffect(() => {
    batteryData && renderChart();
  }, [canvasRef.current, batteryData]);

  return (
    <div>
      <canvas id="myChart" ref={canvasRef} height={300} width={300} />
    </div>
  );
};

export default BatteryVoltage;
