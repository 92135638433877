import { useEffect, useState } from 'react';
import usePrevious from 'flyid-ui-components/dist/hooks/usePrevious';

export function useRemoteData<RawDataType, TransformedDataType>(
  fetchFunc: (() => Promise<RawDataType>) | undefined,
  transformationFunc: ((v: RawDataType) => TransformedDataType) | undefined
): [TransformedDataType | undefined, boolean, Error | null] {
  const [data, setData] = useState<RawDataType>();
  const [transformedData, setTransformedData] = useState<TransformedDataType>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const previousFetch = usePrevious(fetchFunc, undefined);

  useEffect(() => {
    if (previousFetch !== fetchFunc) {
      setLoading(true);
      fetchFunc?.()
        .then((_data) => {
          setData(_data);
          try {
            setTransformedData(transformationFunc?.(_data));
          } catch (err: unknown) {
            setData(undefined);
            setTransformedData(undefined);
            setError(err as Error);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          setData(undefined);
          setTransformedData(undefined);
          setError(err);
          setLoading(false);
        });
    } else if (!!data) {
      setLoading(true);
      setTransformedData(transformationFunc?.(data));
      setLoading(false);
    }
  }, [fetchFunc, transformationFunc]);

  return [transformedData, loading, error];
}
