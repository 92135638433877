import { signOut, signInWithCustomToken, sendPasswordResetEmail } from 'firebase/auth';
import { getDoc } from 'firebase/firestore';
import { getUserProfileDoc, getCompanyDoc } from 'flyid-core/dist/Util/database';
import { buildDocumentRef } from 'src/firebase/firestore';
import { getSnackbar } from 'src/util/helpers/server';
import history from '../../history';
import { updateUi } from '../reducers/uiReducer';
import { ExtendedClaims, setUserProfile } from '../reducers/userReducer';
import { ThunkActionType } from '../store';
import axios from 'axios';
import { ExtraFeatures, MaybeKeyUserPublic } from 'flyid-core/dist/Database/Models';
import { SupportedLocalesType } from '../../util/locale';

export type LoginParams = { email: string; password: string };

// New login
export const loginAction =
  (data: LoginParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLoginLoading: true } }));

    // Fetch custom token from server with user credentials
    axios({
      url: `/analytics/login`,
      method: 'post',
      data: data
    })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const customToken = res.data.token as string | undefined;
        if (customToken) {
          return signInWithCustomToken(getAuth(), customToken);
        }
        throw res;
      })
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user) throw new Error('Something went wrong during login!');
        dispatch(fetchUserData({ uid: user.uid, emailVerified: user.emailVerified }));
        dispatch(updateUi());
        history.push('/');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLoginLoading: false
            }
          })
        );
      });
  };

export type FetchUserDataParams = {
  uid: string;
  emailVerified: boolean;
};
export const fetchUserData =
  (data: FetchUserDataParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const profileRef = buildDocumentRef(getUserProfileDoc(data.uid));
    return Promise.all([
      getDoc(profileRef),
      Promise.resolve(getAuth().currentUser?.getIdTokenResult(true))
    ])
      .then(async ([userProfileSnap, token]) => {
        if (!userProfileSnap.exists()) throw new Error('Missing user profile!');
        if (!token) throw new Error('Missing user profile!');

        const userProfile = userProfileSnap.data() as unknown as MaybeKeyUserPublic;
        const userCompanies: string[] = userProfile.keyUser
          ? (userProfile.company as string[])
          : [userProfile.company as string];

        const extraFeatures: ExtraFeatures = {
          flightLogsAnalytics: false,
          usageAnalytics: false
        };

        await Promise.all(
          userCompanies.map(async (company: string) => {
            const extraFeaturesRef = buildDocumentRef(getCompanyDoc(company));
            const snapshot = await getDoc(extraFeaturesRef);
            const data = snapshot.data()?.extraFeatures;
            if (data?.flightLogsAnalytics) {
              extraFeatures.flightLogsAnalytics = true;
            }
            if (data?.usageAnalytics) {
              extraFeatures.usageAnalytics = true;
            }
          })
        );

        dispatch(
          setUserProfile({
            uid: data.uid,
            emailVerified: data.emailVerified,
            profile: userProfileSnap.data(),
            claims: { ...token.claims, extraFeatures } as ExtendedClaims
          })
        );
      })
      .catch(() => dispatch(updateUi()));
  };

export type LogoutParams = undefined;
export const logoutAction =
  (): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    signOut(getAuth()).catch(() => dispatch(updateUi()));
  };

export type SendPasswordResetEmailParams = { email: string; locale: SupportedLocalesType };
export const sendPasswordResetEmailAction =
  (data: SendPasswordResetEmailParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isForgetPasswordLoading: true } }));

    const languageCode = data.locale.substring(0, 2);

    const auth = getAuth();
    auth.languageCode = languageCode;

    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'forgotPw.sent',
                msg: 'Password recovery instructions were sent to the provided email'
              },
              duration: 3000,
              severity: 'success',
              show: true
            }
          })
        );
        history.push('/');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
        history.push('/');
      });
  };
