import { AppDispatch } from "../store";
import { updateUi } from "../reducers/uiReducer";
import { logoutAction } from "./userActions";
import { Actions } from "./actionsTypes";

export const handleAction = (dispatch: AppDispatch, action: Actions, data: any) => {
  switch (action) {
    case Actions.LOGOUT:
      dispatch(updateUi());
      dispatch(logoutAction());
      break;
    default:
      return;
  }
};
