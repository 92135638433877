import { Query, getAggregateFromServer, sum, average } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const useAvgAndSum = <T>(dataQuery: Query<T> | null): {} => {
  const [sumSnapshot, setSumSnapshot] = useState<{}>({});
  const [avgSnapshot, setAvgSnapshot] = useState<{}>({});

  const getSumAndAverageData = async () => {
    if (dataQuery) {
      const _sumSnapshot = getAggregateFromServer(dataQuery, {
        total: sum('total'),
        empty: sum('empty'),
        invalid: sum('invalid'),
        flightDuration: sum('flightDuration'),
        avgReadingTime: sum('avgReadingTime')
      }).then((result) => {
        setSumSnapshot({
          total: result.data().total,
          empty: result.data().empty,
          invalid: result.data().invalid,
          valid: result.data().total - result.data().invalid,
          flightDuration: result.data().flightDuration,
          avgReadingTime: result.data().avgReadingTime
        });
      });

      const _avgSnapshot = getAggregateFromServer(dataQuery, {
        total: average('total'),
        empty: average('empty'),
        invalid: average('invalid'),
        flightDuration: average('flightDuration'),
        avgReadingTime: average('avgReadingTime')
      }).then((result) => {
        setAvgSnapshot({
          total: result.data().total,
          empty: result.data().empty,
          invalid: result.data().invalid,
          valid: result.data().total! - result.data().invalid!,
          flightDuration: result.data().flightDuration,
          avgReadingTime: result.data().avgReadingTime
        });
      });

      await Promise.all([_sumSnapshot, _avgSnapshot]);
    }
  };

  useEffect(() => {
    getSumAndAverageData();
  }, [dataQuery]);

  return { sum: sumSnapshot, average: avgSnapshot };
};

export default useAvgAndSum;
