import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import NavBar from './components/layouts/NavBar';
import IntlAndTheme from './components/utils/IntlAndTheme';
import { initFirebase } from './firebase/firebase';
import { setupFirebaseListeners } from './firebase/listeners';
import history from './history';
import initStore from './redux/store';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import axios from 'axios';
import environment from '../src/environment';
import GlobalFeedback from './components/layouts/GlobalFeedback';

// Init axios base url with server url
axios.defaults.baseURL = environment.serverApi;

// Make sure firebase is properly initialized
const firebaseData = initFirebase();

export const store = initStore(firebaseData);

setupFirebaseListeners({ store, ...firebaseData });

ReactDOM.render(
  <React.StrictMode>
    {/* Redux provider */}
    <Provider store={store}>
      {/* Translation and theme */}
      <IntlAndTheme>
        {/* Router with history API */}
        <Router history={history}>
          {/* Set up React container */}
          <div className="App">
            {/* Navbar with top toolbar and sidebar */}
            <NavBar>
              {/* Actual content in routes switch */}
              <Routes />
            </NavBar>
            {/* Handle various UI feedback mechanism */}
            <GlobalFeedback />
          </div>
        </Router>
      </IntlAndTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
