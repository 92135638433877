import { UserState } from 'flyid-core/dist/Redux/types/userTypes';
import { getUserSlice } from 'flyid-core/dist/Redux/reducers/userReducer';
import { CustomClaims } from 'flyid-core/dist/Database/Models/Settings/CustomClaims';
import { UserPublic } from 'flyid-core/dist/Database/Models/User';
import { ExtraFeatures } from 'flyid-core/dist/Database/Models';

export type ExtendedClaims = CustomClaims & {
  extraFeatures: ExtraFeatures;
};

const userSlice = getUserSlice<UserPublic, ExtendedClaims>();
const { setUserProfile, setProfileImageData } = userSlice.actions;
const MyUserState = UserState<UserPublic, ExtendedClaims>;

export { MyUserState, setUserProfile, setProfileImageData };
export default userSlice.reducer;
export const userActions = userSlice.actions;
