import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';

type Props = {
  data: {};
  isLoading?: boolean;
};

const SessionsTotalsTable: React.FC<Props> = (props) => {
  const { data, isLoading } = props ?? [];

  const columns: GridColDef[] = [{ field: 'rowTitle', headerName: '', width: 140 }];
  const totalsRow: {} = { rowTitle: 'Totals' };
  const meansRow: {} = { rowTitle: 'Means' };
  const rows = [totalsRow, meansRow];

  //Getting table columns
  Object.keys(Object.values(data)[0] as {}).forEach((k) => {
    columns.push({ field: k, headerName: k, width: 140 });
  });

  //Getting table rows
  Object.assign(totalsRow, Object.values(data)[0]);
  Object.assign(meansRow, Object.values(data)[1]);

  return columns.length > 2 && rows ? (
    <Box sx={{ height: '30.4vh', width: '70vw', marginBottom: 5 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={2}
        loading={isLoading}
        getRowId={() => String(Math.random())}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  ) : null;
};

export default SessionsTotalsTable;
