import { Container } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import { useState, useEffect } from 'react';
import { DayJSConverter } from 'src/util/helpers/dateStateConverter';

type Props = {
  onChange: (dateRangeSelected: [Date, Date] | null) => void;
  useUniqueState?: string;
};

const CustomDatePicker: React.FC<Props> = (props) => {
  const [firstDate, setFirstDate] = useStoredState<Dayjs | undefined>(
    `firstCustomDatePicker${props.useUniqueState ?? ''}`,
    undefined,
    DayJSConverter
  );
  const [lastDate, setLastDate] = useStoredState<Dayjs | undefined>(
    `secondCustomDatePicker${props.useUniqueState ?? ''}`,
    undefined,
    DayJSConverter
  );

  const [firstDatecleared, setFirstDateCleared] = useState<boolean>(false);

  useEffect(() => {
    if (firstDatecleared) {
      setFirstDateCleared(false);
    }
  }, [firstDatecleared]);

  const [lastDateCleared, setLastDateCleared] = useState<boolean>(false);

  useEffect(() => {
    if (lastDateCleared) {
      setLastDateCleared(false);
    }
  }, [lastDateCleared]);

  //console.log(firstDate, lastDate);

  useEffect(
    () => props.onChange(firstDate && lastDate ? [firstDate.toDate(), lastDate.toDate()] : null),
    [firstDate, lastDate]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <Container
        sx={{
          display: 'flex',
          width: '30vw',
          marginTop: 1
        }}
      >
        <DatePicker
          label="First date picker"
          value={firstDate}
          onChange={(newValue) => setFirstDate(newValue ?? undefined)}
          slotProps={{
            field: { clearable: true, onClear: () => setFirstDateCleared(true) }
          }}
          sx={{ marginRight: 2 }}
        />
        <DatePicker
          label="Last date picker"
          value={lastDate}
          onChange={(newValue) => setLastDate(newValue ?? undefined)}
          slotProps={{
            field: { clearable: true, onClear: () => setLastDateCleared(true) }
          }}
          disabled={!firstDate}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
