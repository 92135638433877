import Dexie from 'dexie';
import { FlightLog } from 'flyid-core/dist/Database/Models/HardwareTracking/FlightLogs';
import { buildCollectionRef } from 'src/firebase/firestore';
import { getFlightLogsCol } from 'flyid-core/dist/Util/database';
import { query, getDocs, orderBy, limit, where } from 'firebase/firestore';

class LocalStorage extends Dexie {
  logs!: Dexie.Table<Log, string>;

  constructor(dbName: string) {
    super(dbName);
    this.version(1).stores({
      logs: 'id, data'
    });
  }
}

export interface Log {
  id: string;
  logData: FlightLog;
}

export const db = new LocalStorage('pid-analytics');

export function clearData() {
  Dexie.delete('pid-analytics');
}

export async function fetchFlightLogs(company: string, dateRange: [Date, Date]) {
  try {
    const count = await db.logs.count();

    if (count === 0) {
      const logQuery = query(
        buildCollectionRef(getFlightLogsCol(company)),
        where('createdDate', '>=', dateRange[0]),
        where('createdDate', '<=', dateRange[1]),
        orderBy('createdDate', 'desc'),
        limit(20)
      );
      const snapshot = await getDocs(logQuery);

      snapshot.forEach(async (doc) => {
        await db.logs.add({ id: doc.id, logData: doc.data() });
      });
    } else {
      await db.logs.clear().then(async () => {
        const logQuery = query(
          buildCollectionRef(getFlightLogsCol(company)),
          where('createdDate', '>=', dateRange[0]),
          where('createdDate', '<=', dateRange[1]),
          orderBy('createdDate', 'desc'),
          limit(20)
        );
        const snapshot = await getDocs(logQuery);

        snapshot.forEach(async (doc) => {
          await db.logs.add({ id: doc.id, logData: doc.data() });
        });
      });
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}
