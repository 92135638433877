import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FirestoreState,
  SetAPIKeysData,
  SetAuthLicensesData,
  SetUserProfilesData
} from '../types/firestoreTypes';
import { SetDomainSettingsData } from './../types/firestoreTypes';

const firestoreSlice = createSlice({
  name: 'firestore',
  initialState: new FirestoreState(),
  reducers: {
    // Common data
    setDomainSettings: (state, action: PayloadAction<SetDomainSettingsData>) => {
      state.domainSettings = Object.assign(state.domainSettings ?? {}, action.payload);
    },
    // Moderator data actions
    setUserProfiles: (state, action: PayloadAction<SetUserProfilesData>) => {
      state.userProfiles = Object.assign(state.userProfiles ?? {}, action.payload);
    },
    setAuthLicenses: (state, action: PayloadAction<SetAuthLicensesData>) => {
      state.authLicenses = Object.assign(state.authLicenses ?? {}, action.payload);
    },
    setAPIKeys: (state, action: PayloadAction<SetAPIKeysData>) => {
      state.apiKeys = Object.assign(state.apiKeys ?? {}, action.payload);
    }
  }
});

export const { setDomainSettings, setUserProfiles, setAuthLicenses, setAPIKeys } =
  firestoreSlice.actions;

export default firestoreSlice.reducer;
