import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import checkLogData, { LogData, LogHeaders } from 'src/util/logParser';
import BatteryCellVoltageLevel from '../../charts/BatteryCellVoltageLevel';
import BatteryChargeRemaining from '../../charts/BatteryChargeRemaining';
import BatteryCurrent from '../../charts/BatteryCurrent';
import BatteryLifetimeRemaining from '../../charts/BatteryLifetimeRemaining';
import BatteryTemperature from '../../charts/BatteryTemperature';
import BatteryVoltage from '../../charts/BatteryVoltage';

type Props = {
  file: string;
};

const BatteryCharts: React.FC<Props> = (props) => {
  const { file: inputFile } = props;
  const [file, setFile] = useState<string | null | undefined>(inputFile);

  const [fileHeaders, setFileHeaders] = useState<LogHeaders>();
  const [fileData, setFileData] = useState<LogData>();
  const [fileStartTime, setFileStartTime] = useState<number>();
  const [fileEndTime, setFileEndTime] = useState<number>();

  useEffect(() => setFile(inputFile), [inputFile]);

  useEffect(() => {
    if (file) {
      const [headers, data, startTime, endTime] = checkLogData('fileName', file);

      setFileHeaders(headers);
      setFileData(data);
      setFileStartTime(startTime);
      setFileEndTime(endTime);
    }
  }, [file]);

  const data = {
    headers: fileHeaders,
    data: fileData,
    startTime: fileStartTime,
    endTime: fileEndTime
  };

  return (
    <Box
      sx={{
        width: '30vw',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box>
        <Box sx={{ marginBottom: 10 }}>
          <Box sx={{ marginBottom: 5 }}>
            <BatteryTemperature {...data} />
          </Box>

          <Box sx={{ marginBottom: 5 }}>
            <BatteryChargeRemaining {...data} />
          </Box>

          <Box sx={{ marginBottom: 5 }}>
            <BatteryVoltage {...data} />
          </Box>

          <Box sx={{ marginBottom: 5 }}>
            <BatteryLifetimeRemaining {...data} />
          </Box>

          <Box sx={{ marginBottom: 5 }}>
            <BatteryCurrent {...data} />
          </Box>

          <Box sx={{ marginBottom: 5 }}>
            <BatteryCellVoltageLevel {...data} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BatteryCharts;
