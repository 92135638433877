import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import firestoreReducer from './firestoreReducer';
import localeReducer from './localeReducer';
import pictureReducer from './pictureReducer';

const combinedReducers = combineReducers({
  user: userReducer,
  ui: uiReducer,
  firestore: firestoreReducer,
  locale: localeReducer,
  picture: pictureReducer
});

export default combinedReducers;
