import { cx } from '@emotion/css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridSortModel,
  GridToolbar,
  GridToolbarContainer
} from '@mui/x-data-grid';
import 'dayjs/locale/pt-br';
import { query, where } from 'firebase/firestore';
import { CustomBatteryData } from 'flyid-core/dist/Database/Models/HardwareTracking/BatteryData';
import { getHardwareTrackingBatteriesCol } from 'flyid-core/dist/Util/database';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { buildCollectionRef } from 'src/firebase/firestore';
import { usePaginatedData } from 'src/hooks/paginationHooks';
import useStateReducer from 'flyid-ui-components/dist/hooks/useStateReducer';
import { appMakeStyles } from 'src/theme/theme';
import { getAllBatteriesData } from 'src/util/database';
import { downloadExcel } from 'src/util/xlsx';
import { PageState } from './SessionHistory';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import CustomDatePicker from '../utils/CustomDatePicker';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';
import PermissionWarning from '../widgets/PermissionWarning';

type OutputBatteryData = CustomBatteryData<string, { id: string }>;
type BatteryDataWithId = CustomBatteryData<Date, { id: string }>;

const useStyles = appMakeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'center'
  },
  margin: {
    marginBottom: 20
  },
  selectBox: {
    display: 'flex',
    width: 700
  },
  datagridBox: {
    height: '70vh',
    width: '70vw'
  }
}));

const BatteryHistory: React.FC = () => {
  //User data
  const { userData, claims } = useAppSelector((s) => {
    return {
      userData: selectProfile(s),
      claims: selectProfileClaims(s)
    };
  });

  const classes = useStyles();

  const [companies, setCompanies] = useState<string[]>([]);
  const [orderByColumn, setOrderByColumn] = useState<string | null>(null);
  const [columnOrder, setColumnOrder] = useState<GridSortDirection>(null);

  //Select Button
  const [companySelected, setCompanySelected] = useStoredState<string>(
    'analyticsCompanySelect',
    ''
  );

  const handleCompanyChange = (event) => {
    setCompanySelected(event.target.value);
  };

  //Page State
  const [pageState, setPageState] = useStateReducer<PageState>({
    data: [],
    total: 0,
    page: 0,
    pageSize: 10
  });

  //DatePicker Related
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => setDateRange(value);

  //Data Grid Columns
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 200
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 200
    },
    {
      field: 'pilotName',
      headerName: 'Pilot',
      width: 200
    },
    {
      field: 'droneId',
      headerName: 'Drone ID',
      width: 200
    },
    {
      field: 'batteryId',
      headerName: 'Battery ID',
      width: 200
    },
    {
      field: 'hasNotFlown',
      headerName: 'Drone Has Not Flown',
      width: 200
    },
    {
      field: 'operationDate',
      headerName: 'Operation Date',
      width: 200,
      valueFormatter: (param) => (param.value as Date).toLocaleDateString()
    },
    {
      field: 'startTime',
      headerName: 'Operation Start Time',
      width: 200,
      valueFormatter: (param) => (param.value as Date).toLocaleTimeString()
    },
    {
      field: 'operationDuration',
      headerName: 'Operation Duration',
      width: 200
    },
    {
      field: 'flightStartTime',
      headerName: 'Flight Start Time',
      width: 200,
      valueFormatter: (param) => {
        if (param.value === 0) {
          return 0;
        } else {
          return (param.value as Date).toLocaleTimeString();
        }
      }
    },
    {
      field: 'flightDuration',
      headerName: 'Flight Duration',
      width: 200
    },
    {
      field: 'batteryMaxTemp',
      headerName: 'Battery Max Temperature',
      width: 200
    },
    {
      field: 'batteryAvgTemp',
      headerName: 'Battery Average Temperature',
      width: 200
    },
    {
      field: 'numberOfDischarges',
      headerName: 'Number Of Discharges',
      width: 200
    },
    {
      field: 'minimumChargeRemaining',
      headerName: 'Minimum Charge Remaining',
      width: 200
    },
    {
      field: 'maxCurrent',
      headerName: 'Battery Max Current',
      width: 200
    },
    {
      field: 'avgCurrentBeforeFlight',
      headerName: 'Battery Average Current Before Flight',
      width: 200
    },
    {
      field: 'avgCurrent',
      headerName: 'Battery Average Current',
      width: 200
    },
    {
      field: 'dischargeRate',
      headerName: 'Discharge Rate',
      width: 200
    }
  ];

  //Datagrid Toolbar
  const CustomToolbar = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <GridToolbar />
        <GridToolbarContainer sx={{ paddingLeft: 0 }}>
          <Button
            onClick={() => downloadExcel(formatData(pageState.data))}
            size="small"
            sx={{ padding: 0, marginRight: 1 }}
          >
            <SaveAltIcon sx={{ fontSize: '16.81px', marginRight: 1 }} />
            Export to Excel
          </Button>
          <Button onClick={handleExport} size="small" sx={{ padding: 0 }}>
            <SaveAltIcon sx={{ fontSize: '16.81px', marginRight: 1 }} />
            Export All Data to Excel
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  };

  //Exporting All Data to Excel
  const handleExport = async () => {
    if (dateRange !== null) {
      const batteryData = await getAllBatteriesData(companySelected, dateRange);
      downloadExcel(formatData(batteryData));
    } else alert('No data to export!');
  };

  //Data Transformation (Excel Export)
  const formatData = (data: any[]) =>
    data.map((battery) => {
      const data = {} as OutputBatteryData;

      data.id = `${battery.startTime}`;
      data.company = battery.company;
      data.pilotName = battery.pilotName;
      data.droneId = battery.droneId;
      data.batteryId = battery.batteryId;
      data.hasNotFlown = battery.hasNotFlown;
      data.operationDate = new Date(battery.operationDate).toLocaleDateString();
      data.startTime = new Date(battery.startTime).toLocaleTimeString();
      if (battery.hasNotFlown === true) {
        data.flightStartTime = '0';
      } else {
        data.flightStartTime = new Date(battery.flightStartTime).toLocaleTimeString();
      }
      data.operationDuration = battery.operationDuration;
      data.flightDuration = battery.flightDuration;
      data.batteryMaxTemp = battery.batteryMaxTemp;
      data.batteryAvgTemp = battery.batteryAvgTemp;
      data.numberOfDischarges = battery.numberOfDischarges;
      data.minimumChargeRemaining = battery.minimumChargeRemaining;
      data.maxCurrent = battery.maxCurrent;
      data.avgCurrentBeforeFlight = battery.avgCurrentBeforeFlight;
      data.avgCurrent = battery.avgCurrent;
      data.dischargeRate = battery.dischargeRate;

      return data;
    });

  const onSortModelChange = (model: GridSortModel) => {
    const columnModel = model[0];
    const columnField = columnModel?.field;
    if (columnField === 'startTime' || columnField === 'endTime') {
      setOrderByColumn(columnField);
      setColumnOrder(columnModel.sort);
      setPageState({ page: 0 });
    } else {
      setOrderByColumn(null);
      setColumnOrder(null);
    }
  };

  //Query
  const batteryDataQuery = useMemo(() => {
    if (!companySelected || dateRange === null) return null;
    dateRange[1].setHours(23, 59, 59);

    return query(
      buildCollectionRef(getHardwareTrackingBatteriesCol(companySelected)),
      where('startTime', '>=', dateRange[0]),
      where('startTime', '<=', dateRange[1])
    );
  }, [dateRange, companySelected]);

  const onPageChange = useCallback((page: number) => setPageState({ page }), [setPageState]);

  //Pagination Hook
  const useColumnOrder = orderByColumn === 'startTime';
  const rawBatteryData = usePaginatedData(
    batteryDataQuery,
    pageState.page,
    pageState.pageSize,
    useColumnOrder ? orderByColumn : null,
    useColumnOrder ? columnOrder : null,
    onPageChange
  );

  useEffect(() => {
    claims
      ? claims.keyUser
        ? setCompanies(claims.company as unknown as string[])
        : setCompanies([claims.company])
      : null;
  }, [claims]);

  // Transform Battery Data
  useEffect(() => {
    const _batteryData = rawBatteryData.data;
    if (!_batteryData) return;

    const batteriesData = _batteryData.map((batterySnap) => {
      const batteryData = batterySnap.data();
      const data: BatteryDataWithId = { ...batteryData, id: String(batteryData.startTime) };
      return data;
    });

    setPageState({ data: batteriesData, total: rawBatteryData.count });
  }, [rawBatteryData.data, rawBatteryData.count]);

  return (
    <Box className={classes.flex}>
      {claims?.extraFeatures.flightLogsAnalytics ? (
        <Box>
          <Box className={cx(classes.flex, classes.margin)}>
            <Box className={classes.selectBox}>
              {/* Company Select Button */}
              <FormControl sx={{ width: 200, marginTop: 1 }} fullWidth>
                <InputLabel id="battery-data-company-select-label">Company</InputLabel>
                <Select
                  labelId="battery-data-company-select-label"
                  id="battery-data-company-select"
                  value={companySelected}
                  label="Company"
                  onChange={handleCompanyChange}
                >
                  <MenuItem value={''}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {companies.map((company) => {
                    return (
                      <MenuItem value={company} key={company}>
                        {company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* DatePicker */}
              <CustomDatePicker onChange={handleDateChange} />
            </Box>
          </Box>

          {/* Data Grid */}
          {companySelected && dateRange ? (
            <Box className={classes.datagridBox}>
              <DataGrid
                rows={pageState.data}
                rowCount={pageState.total}
                pageSize={pageState.pageSize}
                loading={rawBatteryData.isLoading}
                page={pageState.page}
                onPageChange={(newPage) => setPageState({ page: newPage })}
                getRowId={(row: any) => row.startTime + ''}
                columns={columns}
                rowsPerPageOptions={[10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageState({ pageSize: newPageSize })}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                components={{
                  Toolbar: CustomToolbar
                }}
                onSortModelChange={onSortModelChange}
              />
            </Box>
          ) : null}
        </Box>
      ) : (
        <PermissionWarning />
      )}
    </Box>
  );
};

export default BatteryHistory;
