import { FirebaseApp } from 'firebase/app';
import { Auth, onAuthStateChanged } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { fetchUserData } from 'src/redux/actions/userActions';
import { setUserProfile } from 'src/redux/reducers/userReducer';
import type { AppStore } from 'src/redux/store';

// Triggers actions whenever auth state changes
function authStateListener(store: AppStore, auth: Auth) {
  onAuthStateChanged(auth, (user) => {
    store.dispatch(
      user
        ? fetchUserData({
            uid: user.uid,
            emailVerified: user.emailVerified
          })
        : setUserProfile(null)
    );
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function setupFirebaseListeners({
  store,
  firebaseApp,
  auth,
  firestore,
  storage
}: /* eslint-enable @typescript-eslint/no-unused-vars */
{
  store: AppStore;
  firebaseApp: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  storage: FirebaseStorage;
}) {
  authStateListener(store, auth);
}
