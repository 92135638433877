import { Box, Alert, Typography } from '@mui/material';

const PermissionWarning: React.FC = () => {
  return (
    <Box sx={{ justifyContent: 'center' }}>
      <Alert severity="warning" sx={{ justifyContent: 'center', marginBottom: 3, marginTop: 3 }}>
        Access denied.
      </Alert>
      <Typography>You don't have permission rights to access this page!</Typography>
    </Box>
  );
};

export default PermissionWarning;
