import { LogoutParams } from "./userActions";

export enum Actions {
  NONE = "NONE",
  //User Actions
  LOGOUT = "LOGOUT"
}

export type ParameterMap = {
  NONE: undefined | null;
  // User Actions
  LOGOUT: LogoutParams;
};
