import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Box, FormControl, OutlinedInput } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { getCount } from '../../../util/database';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { useRemoteData } from 'src/hooks/chartHooks';
import LoadingCircle from 'src/components/widgets/LoadingCircle';
import BadRequest from '../../widgets/BadRequest';
import ChartFilter from 'src/components/utils/ChartFilter';
import CustomDatePicker from 'src/components/utils/CustomDatePicker';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';

export type CountData = {
  sessionCREATE: number;
  sessionDELETE: number;
  sessionUPDATE: number;
  taskCREATE: number;
  taskDELETE: number;
  taskUPDATE: number;
};

const MultiCompanyChart: React.FC = () => {
  // User data and claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  const [companies, setCompanies] = useState<string[]>([]);

  //Multi Select Button
  const [companySelected, setCompaniesSelected] = useState<string[]>([]);

  const handleSelectChange = (value: string[]) => {
    setCompaniesSelected(value);
  };

  //Filter Related
  const [filteredValues, setFilteredValues] = useState<string[]>([]);

  const handleFilterChange = (values: string[]) => {
    const getValues = new Set<string>();
    values.forEach((value: string) => {
      getValues.add(value);
    });
    setFilteredValues(Array.from(getValues));
  };

  //Date Picker Relared
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => {
    if (value) setDateRange(value);
  };

  //Fetching Data
  const fetchCount = useCallback(async () => {
    if (companySelected) {
      if (dateRange) {
        const count = await getCount(companySelected, dateRange).then((count) => {
          return [{ ...count }];
        });
        return count;
      } else {
        const count = await getCount(companySelected).then((count) => {
          return [{ ...count }];
        });
        return count;
      }
    }
    return [];
  }, [companySelected, dateRange]);

  useEffect(() => {
    claims ? setCompanies(claims.company as unknown as string[]) : null;
  }, [claims]);

  //Chart Config
  const xLabels = [
    filteredValues.includes('sessionCREATE') ? null : 'Session Create',
    filteredValues.includes('sessionDELETE') ? null : 'Session Delete',
    filteredValues.includes('sessionUPDATE') ? null : 'Session Update',
    filteredValues.includes('taskCREATE') ? null : 'Task Create',
    filteredValues.includes('taskDELETE') ? null : 'Task Delete',
    filteredValues.includes('taskUPDATE') ? null : 'Task Update'
  ].filter(Boolean);

  const handleData = useCallback(
    (data) => {
      const countData: CountData = {
        sessionCREATE: 0,
        sessionDELETE: 0,
        sessionUPDATE: 0,
        taskCREATE: 0,
        taskDELETE: 0,
        taskUPDATE: 0
      };

      const resetCountData = () => {
        countData.sessionCREATE = 0;
        countData.sessionDELETE = 0;
        countData.sessionUPDATE = 0;
        countData.taskCREATE = 0;
        countData.taskDELETE = 0;
        countData.taskUPDATE = 0;
      };

      const countDataList: {}[] = [];
      const datalist: {}[] = [];

      data.map((_data) => {
        Object.keys(_data).forEach((company) => {
          Object.keys(_data[company]).map((domain: string) => {
            const domainData = _data[company][domain];

            Object.keys(countData).forEach((k) => {
              countData[k] += domainData[k];
            });
          });
          countDataList.push({ company: company, ...countData });
          resetCountData();
        });
      });

      const transformedDataList = Array.from(countDataList).map((obj) => {
        return Object.keys(obj).map((k) => {
          return filteredValues.includes(k) ? null : obj[k];
        });
      });

      const filteredData = transformedDataList.map((obj) => {
        return obj.filter((element) => {
          return element !== null && element !== undefined;
        });
      });

      filteredData.map((obj) => {
        const dataArray: any[] = Object.values(obj);
        const company: string = dataArray.shift()!;

        datalist.push({
          data: dataArray,
          label: company,
          id: `${company}Id`
        });
      });

      return datalist;
    },
    [filteredValues, companySelected]
  );

  const [data, loading, error] = useRemoteData(fetchCount, handleData);

  const dataCheck = data
    ?.map((data) => {
      return Object.keys(data['data']).length === 0 ? null : data['data'];
    })
    .filter(Boolean);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <FormControl
          sx={{
            display: 'flex',
            width: 250,
            justifyContent: 'center',
            m: 1,
            marginRight: 2
          }}
        >
          <MultiSelectButton
            options={companies}
            onChange={handleSelectChange}
            selectProps={{
              labelId: 'companies-select-label',
              input: <OutlinedInput label={'companies'} />
            }}
          />
        </FormControl>
        <CustomDatePicker onChange={handleDateChange} />
        {companySelected.length && data ? <ChartFilter onChange={handleFilterChange} /> : null}
      </Box>

      {!companySelected.length ? null : loading ? (
        <Box sx={{ marginTop: 10 }}>
          <LoadingCircle />
        </Box>
      ) : error ? (
        <Box sx={{ marginTop: 5 }}>
          <BadRequest text={error.message} />
        </Box>
      ) : data?.length && dataCheck?.length ? (
        <BarChart
          height={companySelected.length >= 15 ? 600 : 450}
          width={companySelected.length >= 15 ? 1200 : 900}
          margin={{ top: 120, bottom: 70, left: 100, right: 200 }}
          series={data}
          xAxis={[{ data: xLabels, scaleType: 'band' }]}
          slotProps={{
            legend: {
              direction: 'column',
              position: {
                vertical: 'top',
                horizontal: 'right'
              }
            }
          }}
          sx={{
            '--ChartsLegend-rootOffsetX': '5px',
            '--ChartsLegend-rootOffsetY': `${companySelected.length * 15}px`,
            '--ChartsLegend-itemMarkSize': '8px',
            '--ChartsLegend-labelSpacing': '5px',
            '--ChartsLegend-rootSpacing': '9px'
          }}
        />
      ) : null}
    </Box>
  );
};

export default MultiCompanyChart;
