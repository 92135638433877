import { useState, useEffect } from 'react';
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  options: string[];
  onChange: (selectedOptions: string[]) => void;
};

const ChartDomainFilter: React.FC<Props> = (props) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  const [valueSelected, setValueSelected] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    setValueSelected(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => props.onChange(valueSelected), [valueSelected]);

  return (
    <FormControl sx={{ marginTop: 1, marginBottom: 1, marginLeft: 1, width: 240 }}>
      <InputLabel id="chart-domain-filter-select">Filter by Domain (Optional)</InputLabel>
      <Select
        labelId="chart-domain-filter-select"
        id="chart-domain-filter-select-checkbox"
        multiple
        value={valueSelected}
        onChange={handleChange}
        input={<OutlinedInput label="Filter by Domain (Optional)" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option}>
            <Checkbox checked={!(valueSelected.indexOf(option) > -1)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChartDomainFilter;
