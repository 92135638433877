import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';
import { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { fetchFlightLogs } from 'src/util/localstorage';
import { useLogReferences } from 'src/hooks/localDatabaseHooks';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import pako from 'pako';
import BatteryCharts from '../dashboard/BatteryCharts';
import PermissionWarning from '../widgets/PermissionWarning';
import CustomDatePicker from '../utils/CustomDatePicker';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';

const FlightLogCharts: React.FC = () => {
  // Getting user data and claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  const [companies, setCompanies] = useState<string[]>([]);

  // Fetching flight logs
  const logs = useLogReferences();

  // Select Button
  const [companySelected, setCompanySelected] = useStoredState<string>(
    'analyticsCompanySelect',
    ''
  );
  const handleCompanySelectChange = (event) => {
    setCompanySelected(event.target.value as string);
  };

  const [logSelected, setLogSelected] = useState<String>('');
  const handleSelectChange = (event) => {
    setLogSelected(event.target.value as string);
    showLog(event.target.value as string);
  };

  const [loading, setLoading] = useState<boolean>(false);

  // Datepicker
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => setDateRange(value);

  // Storage operations
  const [file, setFile] = useState<any>();

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      const inflated = pako.inflate(evt!.target.result, { to: 'string' });
      setFile(inflated);
    };
    reader.readAsArrayBuffer(file);
  };

  const showLog = (log: string) => {
    setLoading(true);
    const logRef = ref(storage, 'gs://flyid-flight-logs/flightLogs/' + log);
    getDownloadURL(logRef)
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = async (event) => {
          const blob = xhr.response;
          readFile(blob);
          setLoading(false);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    claims
      ? claims.keyUser
        ? setCompanies(claims.company as unknown as string[])
        : setCompanySelected(claims.company)
      : null;
  }, [claims]);

  useEffect(() => {
    if (companySelected && dateRange) fetchFlightLogs(companySelected, dateRange);
  }, [companySelected, dateRange]);

  return (
    <Box>
      {claims?.extraFeatures.flightLogsAnalytics ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {/* Company Select Button */}
            {claims?.keyUser ? (
              <FormControl sx={{ width: '15vw', marginTop: 3 }} fullWidth>
                <InputLabel id="flight-logs-companies-select-label">Company</InputLabel>
                <Select
                  labelId="flight-logs-companies-select-label"
                  id="flight-logs-companies-select"
                  value={companySelected}
                  label="Companies"
                  onChange={handleCompanySelectChange}
                >
                  <MenuItem value={''}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {companies.map((company) => {
                    return (
                      <MenuItem value={company} key={company}>
                        {company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}

            <Box sx={{ marginTop: 2 }}>
              <CustomDatePicker onChange={handleDateChange} />
            </Box>
          </Box>

          {/* Logs Select Button */}
          {companySelected && dateRange && logs ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl sx={{ width: '30vw', marginTop: 3, marginBottom: 5 }} fullWidth>
                <InputLabel id="flight-logs-select-label">Flight Logs</InputLabel>
                <Select
                  labelId="flight-logs-select-label"
                  id="flight-logs-select"
                  value={logSelected}
                  label="Flight Logs"
                  onChange={handleSelectChange}
                  disabled={companySelected ? false : true}
                >
                  <MenuItem value={''}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {logs.map((log) => {
                    return (
                      <MenuItem value={log.id} key={log.id}>
                        {log.id} - {log.logData['DETAILS.company']} -{' '}
                        {new Date(log.logData.createdDate.seconds * 1000).toLocaleDateString()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          {logSelected ? (
            loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <BatteryCharts file={file} />
            )
          ) : null}
        </Box>
      ) : (
        <PermissionWarning />
      )}
    </Box>
  );
};

export default FlightLogCharts;
