import { MenuProps } from '@mui/material';
import { enUS, esES, Localization, ptBR } from '@mui/material/locale';
import { createTheme, Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { pidExtraTheme } from 'flyid-core/dist/Theme';
import { SupportedLocalesType } from 'src/util/locale';

const extraTheme = {
  drawer: {
    width: '15vw'
  },
  ...pidExtraTheme,
  form: {
    maxWidth: '768px'
  }
};

export type ExtraThemeType = typeof extraTheme;

type AppTheme = Theme & ExtraThemeType;

const theme = createTheme({
  palette: {
    background: {
      default: '#fafafa'
    },
    primary: {
      light: '#8c5ac2',
      main: '#5B2E91',
      dark: '#2b0062',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#9ef062',
      main: '#6bbd30',
      dark: '#368c00',
      contrastText: '#FFFFFF'
    }
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  ...extraTheme
}) as Theme & ExtraThemeType;

const resizableContainer = (horizontalPadding: number, verticalPadding = 2) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(
      2 * verticalPadding,
      7 * horizontalPadding,
      2 * verticalPadding,
      7 * horizontalPadding
    )
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(
      1.5 * verticalPadding,
      5 * horizontalPadding,
      1.5 * verticalPadding,
      5 * horizontalPadding
    )
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(
      verticalPadding,
      2 * horizontalPadding,
      verticalPadding,
      2 * horizontalPadding
    )
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(verticalPadding, horizontalPadding, verticalPadding, horizontalPadding)
  }
});

const MuiLocales: Record<SupportedLocalesType, Localization> = {
  'en-GB': enUS,
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES
};

const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;
const SELECT_MAX_ITEMS = 10;
const select = {
  getMenuProps: (extra?: Partial<MenuProps>): Partial<MenuProps> => ({
    PaperProps: {
      style: {
        maxHeight: SELECT_ITEM_HEIGHT * SELECT_MAX_ITEMS + SELECT_ITEM_PADDING_TOP
      }
    },
    ...extra
  }),
  inFormControl: {
    margin: theme.spacing(1, 1, 1, 0)
  }
};

const ThemeExtensions = {
  /** Spacing applied to correctly place content when drawer is showing */
  resizableContainer,
  /** Standardized MUI Select styles */
  select
};

export type ThemeExtensionsType = typeof ThemeExtensions;

// Common for all nodes
const getTheme = (locale: SupportedLocalesType) =>
  createTheme(theme, ThemeExtensions, MuiLocales[locale]);

export const appMakeStyles: typeof makeStyles<AppTheme> = (styles, options) =>
  makeStyles<AppTheme>(styles, options);

const useAppTheme = useTheme<AppTheme>;
export { useAppTheme };

export default getTheme;
