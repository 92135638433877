import * as React from 'react';
import { Box, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import SingleCompanyChart from './usagecounter/SingleCompanyChart';
import MultiCompanyChart from './usagecounter/MultiCompanyChart';
import MultiDomainChart from './usagecounter/MultiDomainChart';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';
import PermissionWarning from '../widgets/PermissionWarning';

const UsageCounter: React.FC = () => {
  // User data and claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  // Radio Config
  const [radioActive, setRadioActive] = React.useState('singlecompany');

  const handleRadioChange = (event) => {
    setRadioActive(event.target.value);
  };

  return (
    <Box>
      {claims?.extraFeatures.usageAnalytics ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormControl sx={{ marginBottom: 3 }}>
              <FormLabel id="chart-radio">Chart Display</FormLabel>
              <RadioGroup
                row
                aria-labelledby="chart-radio"
                defaultValue="singlecompany"
                name="radio-buttons-group"
                value={radioActive}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="singlecompany"
                  control={<Radio />}
                  label="Single Company"
                />
                <FormControlLabel value="multidomain" control={<Radio />} label="Multi Domains" />
                {claims?.keyUser ? (
                  <FormControlLabel
                    value="multicompany"
                    control={<Radio />}
                    label="Multi Companies"
                  />
                ) : null}
              </RadioGroup>
            </FormControl>
          </Box>

          {/* Charts */}
          {radioActive === 'singlecompany' ? <SingleCompanyChart /> : null}
          {radioActive === 'multidomain' ? <MultiDomainChart /> : null}
          {claims?.keyUser ? radioActive === 'multicompany' ? <MultiCompanyChart /> : null : null}
        </>
      ) : (
        <PermissionWarning />
      )}
    </Box>
  );
};

export default UsageCounter;
