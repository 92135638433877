"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationSettingsConverter = exports.PersistenceSettingsConverter = exports.InterfaceSettingsConverter = exports.DefaultPushSettings = exports.DefaultSessionPushSettings = exports.DefaultSessionParserSettings = void 0;
const firestore_1 = require("../../Util/firestore");
exports.DefaultSessionParserSettings = {
    flattenOutput: false,
    noBaseFields: false,
    useInputOrder: false,
    useCustomTransformer: false
};
exports.DefaultSessionPushSettings = {
    pushUrl: '',
    deleteOnPush: false
};
exports.DefaultPushSettings = Object.assign(Object.assign({}, exports.DefaultSessionPushSettings), exports.DefaultSessionParserSettings);
exports.InterfaceSettingsConverter = (0, firestore_1.DirectConverter)();
exports.PersistenceSettingsConverter = (0, firestore_1.DirectConverter)();
exports.AuthenticationSettingsConverter = (0, firestore_1.DirectConverter)();
