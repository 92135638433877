import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  options: string[];
  initialSelection?: string[];
  /** onChange must return the changed value in order to enable data modification middleware */
  onChange: (newData: string[]) => string[] | void;
  selectProps?: SelectProps<string[]>;
  itemRender?: (value: string) => JSX.Element;
};

const MultiSelectButton: React.FC<Props> = (props) => {
  const { $t } = useIntl();
  const options = props.options;

  const [selectedValues, setSelectedValues] = useState<string[]>(props.initialSelection ?? []);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selection = event.target.value as string[];
    let newValue = selection;
    if (selection[selection.length - 1] === 'all') {
      newValue = selectedValues.length === options.length ? [] : options;
    }
    setSelectedValues(props.onChange?.(newValue) ?? newValue);
  };

  useEffect(() => {
    if (props.initialSelection) setSelectedValues(props.initialSelection);
  }, [props.initialSelection]);

  return (
    <Select
      multiple
      value={selectedValues}
      onChange={handleChange}
      renderValue={(selected) => selected.join(', ')}
      {...props.selectProps}
    >
      <MenuItem value="all">
        <ListItemIcon>
          <Checkbox
            checked={options.length > 0 && selectedValues.length === options.length}
            indeterminate={selectedValues.length > 0 && selectedValues.length < options.length}
          />
        </ListItemIcon>
        <ListItemText primary={$t({ id: 'selectAll' })} />
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          <ListItemIcon>
            <Checkbox checked={selectedValues.indexOf(option) > -1} />
          </ListItemIcon>
          {props.itemRender?.(option) ?? <ListItemText primary={option} />}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectButton;
